
import Navbar from './components/navbar/Navbar';
import Banner from './components/home/Banner';

function App() {
  return (
    <>
    <Navbar />
    <Banner />
    </>
  );
}

export default App;
